import { inview } from "./assets/js/modules/inview.js";
import { pageTop } from "./assets/js/modules/pageTop.js";
import { pageTopBtnAction } from "./assets/js/modules/pagetopbtn-action.js";
import { iframeAddClass } from "./assets/js/modules/iframeAddClass.js";
import { accordion } from "./assets/js/modules/accordion.js";

const $body = $("body");
pageTopBtnAction();
accordion();

if ($body.hasClass("top")) {
  inview();
  pageTop();
  iframeAddClass();
}
