export const iframeAddClass = () => {
  $(function () {
    $(document).ready(function () {
      // .p-top-youtube__inner 直下の iframe 要素を取得
      const iframeElement = $(".p-top-youtube__inner > iframe");

      // iframe 要素に新しいクラスを追加
      iframeElement.addClass("p-top-youtube__iframe");
    });
  });
};
