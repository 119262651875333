export const accordion = () => {
  $(function () {
    $(".js-accordion").each(function () {
      $(this).click(function () {
        if (!$(this).hasClass("is-open")) {
          $(this).addClass("is-open");
          $(this).next().stop().slideDown();
        } else {
          $(this).removeClass("is-open");
          $(this).next().stop().slideUp();
        }
      });
    });
  });
};
