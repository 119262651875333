export const pageTop = () => {
  $(function () {
    class slider {
      constructor(classSlider, option) {
        this.optionSlick = option;
        this.class = $(classSlider);
      }
      animate(ele, classActive = ".active") {
        let scaleRatio = 392 / 727;
        let gap = 4;
        const transx1 = function () {
          return (scaleRatio * 100) / 1.47 - gap;
        };
        const transx2 = function (index) {
          return 75 * index + transx1();
        };
        ele.find(classActive).css("transform", "scale(1)");
        ele
          .find(classActive)
          .prevAll()
          .each(function (index, _) {
            $(this).css(
              "transform",
              "scale(" +
                scaleRatio +
                ") translateX(" +
                (index === 0 ? transx1() : transx2(index)) +
                "%)"
            );
          });
        ele
          .find(classActive)
          .nextAll()
          .each(function (index, _) {
            $(this).css(
              "transform",
              "scale(" +
                scaleRatio +
                ") translateX(-" +
                (index === 0 ? transx1() : transx2(index)) +
                "%)"
            );
          });
      }
      initSlider() {
        let animate = this.animate;

        this.class.on("init", function (_, slick) {
          $(this).find(".slick-slide").css("transition", "none");
          $(this).find(".slick-current").addClass("active");
          $(".people_index_current").html(slick.currentSlide + 1);
          $(".people_index_total").html(slick.$slides.length);
          animate.call(slider, $(this));
        });
      }
      // On before slide change
      before() {
        let animate = this.animate;
        this.class.on(
          "beforeChange",
          function (event, slick, currentSlide, nextSlide) {
            $(this).find(".slick-slide").css("transition", "1s");
            slick.$slides[currentSlide].classList.remove("active");
            slick.$slides[nextSlide].classList.add("active");
            $(".people_index_current").html(nextSlide + 1);
            animate.call(slider, $(this));

            if (nextSlide === 0 && currentSlide === slick.$slides.length - 1) {
              slick.$slides[currentSlide].nextSibling.classList.add("active");
              animate.call(slider, $(this), ".slick-cloned.active");
            }
            if (currentSlide === 0 && nextSlide === slick.$slides.length - 1) {
              slick.$slides[currentSlide].previousSibling.classList.add(
                "active"
              );
              animate.call(slider, $(this), ".slick-cloned.active");
            }
          }
        );
      }
      // On after slide change
      after() {
        let animate = this.animate;
        this.class.on(
          "afterChange",
          function (event, slick, currentSlide, nextSlide) {
            $(this).find(".slick-slide").css("transition", "none");
            $(this).find(".slick-cloned").removeClass("active");
            animate.call(slider, $(this));
          }
        );
      }
      play() {
        this.initSlider();
        this.class.slick(this.optionSlick);
        this.before();
        this.after();
      }
    }

    $(document).ready(function () {
      $(".p-top-slider").on(
        "init",
        function (event, slick, currentSlide, nextSlide) {
          if (slick.$slides.length < 10) {
            $(".p-top-slider__last").text(`0${slick.$slides.length}`);
          } else {
            $(".p-top-slider__last").text(slick.$slides.length);
          }
        }
      );
      const slider01 = new slider(".p-top-slider", {
        dots: true,
        infinite: true,
        arrows: true,
        speed: 1000,
        autoplay: true,
        autoplaySpeed: 3000,
        slidesToShow: 2,
        slidesToScroll: 1,
        appendArrows: $(".p-top-slider__arrows"),
        appendDots: $(".p-top-slider__dots"),
      });
      slider01.play();

      $(".p-top-slider").on(
        "beforeChange",
        function (event, slick, currentSlide, nextSlide) {
          if (nextSlide < 10) {
            $(".p-top-slider__first").text(`0${nextSlide + 1}`);
          } else {
            $(".p-top-slider__first").text(nextSlide + 1);
          }
        }
      );

      $(".p-top-slider01").on(
        "init",
        function (event, slick, currentSlide, nextSlide) {
          if (slick.$slides.length < 10) {
            $(".p-top-slider01__last").text(`0${slick.$slides.length}`);
          } else {
            $(".p-top-slider01__last").text(slick.$slides.length);
          }
        }
      );
      $(".p-top-slider01").slick({
        dots: true,
        infinite: true,
        arrows: true,
        speed: 1000,
        autoplay: true,
        autoplaySpeed: 3000,
        slidesToShow: 1,
        slidesToScroll: 1,
        appendArrows: $(".p-top-slider01__arrows"),
        appendDots: $(".p-top-slider01__dots"),
      });
      $(".p-top-slider01").on(
        "beforeChange",
        function (event, slick, currentSlide, nextSlide) {
          if (nextSlide < 10) {
            $(".p-top-slider01__first").text(`0${nextSlide + 1}`);
          } else {
            $(".p-top-slider01__first").text(nextSlide + 1);
          }
        }
      );

      $(".p-top-slider02").slick({
        dots: true,
        infinite: true,
        arrows: true,
        // speed: 1000,
        speed: 1500,
        autoplay: true,
        // autoplaySpeed: 3000,
        autoplaySpeed: 4000,
        slidesToShow: 1,
        slidesToScroll: 1,
        appendArrows: $(".p-top-slider02__arrows"),
        appendDots: $(".p-top-slider02__dots"),
      });
    });
    $(window).bind("resize load", function () {
      if ($(this).width() >= 1200) {
        let array = [];
        $(".p-top-department__box-content .p-top__text").each(function () {
          array.push($(this).height());
        });
        $(".p-top-department__box-content .p-top__text").css(
          "height",
          `${Math.max(...array)}px`
        );
      } else {
        $(".p-top-department__box-content .p-top__text").removeAttr("style");
      }
    });

    $(".p-top-newspost").mCustomScrollbar({
      theme: "dark",
    });

    $(".p-top-newslist__link").click(function () {
      let id = $(this).data("id");
      console.log(id);
      $(".p-top-newslist__link, .p-top-news__content").removeClass("active");
      $(this).addClass("active");
      $("#post_" + id).addClass("active");
    });
  });
};
