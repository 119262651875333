export const pageTopBtnAction = () => {
  $(function () {
    const body = $("body");
    const speed = 1000;

    $(window).on("load", function () {
      //移動アニメーション

      // スムーススクロール
      $('a[href^="#"]').click(function () {
        if (!$(this).hasClass(".js-page-top")) {
          const windowSize = window.innerWidth;
          const href = $(this).attr("href");
          let headerH = $("header").height();

          const target = $(href == "#" || href == "" ? "html" : href);

          // console.log(headerH);

          const position = target.offset().top - headerH;
          $("html, body").animate({ scrollTop: position }, speed);
        }
        return false;
      });
    });

    // ページトップボタンの制御
    // 表示の記述
    function pageTop() {
      let scrollY = window.scrollY;

      if (!body.hasClass("is-nav-open")) {
        if (scrollY !== 0) {
          $(".c-page-top-btn").addClass("is-show");
        } else {
          $(".c-page-top-btn").removeClass("is-show");
        }
      }
    }

    $(window).on("load scroll", function () {
      pageTop();
    });
  });
};
